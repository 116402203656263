import React, { Component } from 'react';
import logo from './assets/logo.svg';
import still1 from './assets/4.png';
import still2 from './assets/3.png';
import still3 from './assets/2.png';
import insta from './assets/insta.svg';
import vimeo from './assets/vimeo.svg';
import './App.css';
import Countdown from 'react-countdown-now';

class App extends Component {
  render() {

    const renderer = ({ days, hours, minutes,seconds }) => {
    
        return (<>
        
        <div className="wrapper-counter"><div className="counter">{days}</div><p>days</p></div>
        <div className="wrapper-counter"><div className="counter">{hours}</div><p>hours</p></div>
        <div className="wrapper-counter"><div className="counter">{minutes}</div><p>minutes</p></div>
        <div className="wrapper-counter"><div className="counter">{seconds}</div><p>Seconds</p></div>
        
        </>)
      
    };

    return (
      <>
        <div className="container">
          <div className="logo"><img alt="Logo" src={logo} /></div>
        </div>
        <div className="container">
          <div className="grid-stills">
            <div className="still">
              <img src={still2} alt="cenas" />
            </div>
            <div className="still">
              <img src={still3} alt="cenas" />
            </div>
            <div className="still">
              <img src={still1} alt="cenas" />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="know-more">
            <p>see more in <a href="https://vimeo.com/shotandcutfilms" rel="self" target="_blank">vimeo</a></p></div>
        </div>
        <div className="container">
          <p>This site will be launched in</p>
        </div>
        <div className="container mega-bottom">
        <Countdown
          date={'Mon, 06 may 2019 00:00:00'}
          renderer={renderer}
        />
        </div>
        <div className="container">
          <footer>
            <div className="copyright"><small>Copyright © All rights reserved</small></div>
            <div className="social">
            <a href="https://www.instagram.com/shotandcutfilms/" rel="self" target="_blank"><img src={insta}/></a>
            <a href="https://vimeo.com/shotandcutfilms" rel="self" target="_blank"><img src={vimeo}/></a>
            </div>
          </footer>
        </div>
      </>
    );
  }
}

export default App;
